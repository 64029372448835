//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Location } from "@angular/common";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { HierarchyUtil } from "./s25.event.type.hierarchy.util";
import { EventTypeFormI } from "../../pojo/HierarchyI";
import { LockService } from "../../services/lock.service";
import { Api } from "../../api/api";
import { DropdownApi } from "../s25-dropdown/dropdown.api";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { OlsService } from "../../services/ols.service";
import { jSith } from "../../util/jquery-replacement";
import { RichTextEditorApi } from "../s25-rich-text-editor/richTextEditor.api";
import { FlsService } from "../../services/fls.service";
import { UserprefService } from "../../services/userpref.service";
import { AlertService } from "../../services/alert.service";

export class AdvCabinetFolderApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(
            relativeElem,
            "s25-ng-adv-cabinet-folder",
            null,
            null,
            (comp: S25AdvCabinetFolderComponent) => {
                comp && comp.ngOnInit();
            },
        );
    }
}
@TypeManagerDecorator("s25-ng-adv-cabinet-folder")
@Component({
    selector: "s25-ng-adv-cabinet-folder",
    template: `
        <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
        @if (this.init && this.event && this.type != "view" && !isCopy) {
            <div>
                <h3 class="c-margin-top--half">{{ this.isEdit ? "Edit" : "New" }} {{ this.name }}</h3>
                @if (this.cabinets && !this.isEdit && !this.isCopy) {
                    <div>
                        @if (!this.parentName) {
                            <label class="c-margin-right--single c-margin-top--half">
                                <span class="ngBlock c-margin-bottom--quarter" id="cabinet"
                                    ><span class="ngBold">Cabinet Type</span
                                    ><span class="c-required">- Required</span></span
                                >
                                <s25-generic-dropdown
                                    [items]="this.cabinets"
                                    [placeholder]="'Select a Cabinet Type'"
                                    [searchEnabled]="true"
                                    [(chosen)]="this.chosenCabinet"
                                    (chosenChange)="onChangeCabinet($event)"
                                ></s25-generic-dropdown>
                            </label>
                        }
                        @if (this.type === "F" && this.hasSubCabinets) {
                            <div>
                                <label class="c-margin-right--single c-margin-top--half"
                                    ><span class="ngBlock c-margin-bottom--quarter" id="subcabinet"
                                        ><span class="ngBold">Cabinet Name</span>
                                        <span class="c-required">- Required</span></span
                                    >
                                    <s25-ng-hierarch-dropdown
                                        [items]="this.subCabinetsFilter"
                                        [searchEnabled]="true"
                                        [(chosen)]="this.chosenSubCabinet"
                                        (chosenChange)="onChangeSubCabinet($event)"
                                    ></s25-ng-hierarch-dropdown>
                                </label>
                                @if (hasSubFolder && showNestedFolders) {
                                    <div>
                                        <s25-ng-create-subfolder
                                            [folders]="folders"
                                            [event]="event"
                                            [allTypes]="allTypes"
                                            [setParent]="setParentValues"
                                            [getDate]="getDate"
                                            [staticItems]="subFolderStaticItems"
                                            [chosenCabinet]="chosenSubCabinet"
                                        >
                                        </s25-ng-create-subfolder>
                                    </div>
                                }
                                @if (
                                    this.type === "F" &&
                                    this.hasSubCabinets &&
                                    !this.isEdit &&
                                    this.subCabinetsFilter.length === 0 &&
                                    this.addSubFolder
                                ) {
                                    <div class="ngBold ngRed">
                                        Unable to add folder as there are no cabinets associated with the selected
                                        cabinet type
                                    </div>
                                }
                                <div>
                                    <label class="c-margin-right--single c-margin-top--half"
                                        ><span class="ngBlock c-margin-bottom--quarter" id="foldertype"
                                            ><span class="ngBold">Folder Type</span
                                            ><span class="c-required">- Required</span>
                                        </span>
                                        <s25-ng-folder-type-dropdown
                                            [items]="this.eventTypes"
                                            [searchEnabled]="true"
                                            [(chosen)]="this.chosenEventType"
                                            (chosenChange)="onChangeEventType($event)"
                                        ></s25-ng-folder-type-dropdown>
                                    </label>
                                </div>
                                @if (this.isDirty && !this.event.event_type_id) {
                                    <div class="c-margin-top--half  ngBold cn-alert cn-alert--warning">
                                        Please select a folder type.
                                    </div>
                                }
                            </div>
                        }
                        @if (this.showCabientErrorMsg) {
                            <div class="c-margin-top--half  ngBold cn-alert cn-alert--warning">
                                Please select a {{ this.type === "C" ? "cabinet type" : "cabinet" }}.
                            </div>
                        }
                    </div>
                }
                @if (
                    (this.type === "F" && this.hasSubCabinets && !this.isEdit && this.subCabinetsFilter.length > 0) ||
                    this.type === "C" ||
                    this.typeId > 0
                ) {
                    <div>
                        <label class="c-margin-right--single c-margin-top--half" for="eventName">
                            <span class="ngBlock c-margin-bottom--quarter"
                                ><span class="ngBold">{{ this.name }} Name </span
                                ><span class="c-required">- Required</span></span
                            >
                            <input
                                class="c-input cn-form__control"
                                id="eventName"
                                [value]="this.event.event_name"
                                (change)="onChangeName($event)"
                                (blur)="onChangeName($event)"
                                type="text"
                                maxlength="80"
                            />
                        </label>
                        @if (this.isEdit) {
                            <div class="ngBold c-margin-top--half">{{ this.name }} Type</div>
                        }
                        @if (this.isEdit) {
                            <div>{{ this.event.event_type_name }}</div>
                        }
                        @if (this.type === "F") {
                            <div>
                                <div class="ngBold c-margin-top--half">Description</div>
                                <div class="c-margin-top--quarter">
                                    <s25-ng-rich-text-editor
                                        [(modelValue)]="this.eventText"
                                        autoFocus="false"
                                        (onEditorContentChange)="this.onChangeDes($event)"
                                    ></s25-ng-rich-text-editor>
                                </div>
                            </div>
                        }
                        <div class="ngBold c-margin-top--half">Date Range</div>
                        <label class="rose-patternPicker-label c-margin-top--half"
                            >Start Date:
                            <s25-ng-editable-date
                                [(val)]="this.startDate.date"
                                (valChange)="onChangeDate($event, 'start')"
                                [alwaysEditing]="true"
                            ></s25-ng-editable-date>
                        </label>
                        <label class="rose-patternPicker-label c-margin-top--half c-margin-left--half"
                            >End Date:
                            <s25-ng-editable-date
                                [(val)]="this.endDate.date"
                                (valChange)="onChangeDate($event, 'end')"
                                [alwaysEditing]="true"
                            ></s25-ng-editable-date>
                        </label>
                        @if (!this.validateStartDate || !this.validateEndDate) {
                            <div class="ngBold ngRed">
                                Date must be inside the cabinet/folder dates between {{ this.parentStartDate }} and
                                {{ this.parentEndDate }}.
                            </div>
                        }
                        @if (this.isValidDate !== true) {
                            <div class="ngBold ngRed">{{ this.isValidDate }}</div>
                        }
                        @if (this.isCatShow === "T") {
                            <div>
                                <label class="hierarchy--section-label ngBold" for="cat">Categories</label>
                                <div id="cat">
                                    <ng-container>
                                        <s25-ng-multiselect-search-criteria
                                            [selectedItems]="catSelectedItems"
                                            [modelBean]="this.modelBeanCat"
                                            honorMatching="true"
                                            type="eventCategories"
                                        ></s25-ng-multiselect-search-criteria>
                                    </ng-container>
                                </div>
                            </div>
                        }
                        <div class="c-margin-bottom--single c-objectDetails c-objectDetails--borderedSection">
                            <div class="c-sectionHead">
                                <label class="simpleCollapseLabel ngBold">Constraints</label>
                            </div>
                            <s25-simple-collapse [titleText]="'Constraints'">
                                <div class="simpleCollapseContent">
                                    @if (this.eventProfile.length > 0) {
                                        <div>
                                            @for (p of this.eventProfile; track p; let i = $index) {
                                                <div class="c-margin-top--half c-padding-bottom--half">
                                                    @if (p && p.status != "del") {
                                                        <div>
                                                            <div class="c-svgIcon ngCpointer ngFloatRight">
                                                                <svg
                                                                    (click)="removeProfile(p.profile_id)"
                                                                    class="c-svgIcon ngCpointer"
                                                                    role="img"
                                                                >
                                                                    <title>Delete</title>
                                                                    <use
                                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                                                    ></use>
                                                                </svg>
                                                            </div>
                                                            <div>
                                                                <s25-ng-constraints
                                                                    [validateConstraint]="this.validateProfile"
                                                                    [profileModel]="p"
                                                                    (profileModelChange)="
                                                                        setProfileStatus(p.profile_id, i)
                                                                    "
                                                                ></s25-ng-constraints>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div>
                                        <button
                                            class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                            (click)="addConstraint()"
                                        >
                                            New Constraint
                                        </button>
                                    </div>
                                </div>
                            </s25-simple-collapse>
                        </div>
                        @if (this.type === "F" && this.isPrimaryOrgInit) {
                            <div class="c-margin-top--half">
                                <label class="hierarchy--section-label ngBold" for="cat">Primary Organization</label>
                                <div id="primaryOrg">
                                    <ng-container>
                                        <s25-ng-dropdown-search-criteria
                                            [type]="'organizations'"
                                            [(chosen)]="this.primaryOrg"
                                            (chosenChange)="chosenOrg($event)"
                                        ></s25-ng-dropdown-search-criteria>
                                        @if (this.primaryOrg && this.primaryOrg.itemId) {
                                            <button
                                                class="aw-button aw-button--danger--transparent elt--primaryOrg-margin-top"
                                                (click)="removePrimaryOrg()"
                                            >
                                                Remove
                                            </button>
                                        }
                                    </ng-container>
                                </div>
                            </div>
                        }
                        @if (this.type === "F" && this.isAddOrgInit) {
                            <div class="c-margin-top--single">
                                <label class="hierarchy--section-label ngBold" for="otherOrg"
                                    >Additional Organizations</label
                                >
                                <div id="otherOrg">
                                    <ng-container>
                                        <s25-ng-multiselect-search-criteria
                                            [selectedItems]="othersOrgSelectedItems"
                                            [modelBean]="this.modelBeanOthersOrg"
                                            honorMatching="true"
                                            type="organizations"
                                        ></s25-ng-multiselect-search-criteria>
                                    </ng-container>
                                </div>
                            </div>
                        }
                        @if (this.canEdit) {
                            <div class="c-displayBlock c-margin-top--single">
                                <button
                                    class="aw-button aw-button--primary c-margin-right--quarter"
                                    (click)="save()"
                                    [disabled]="
                                        !this.event.event_type_id ||
                                        !this.isValidDate ||
                                        !this.validateEndDate ||
                                        !this.validateStartDate
                                    "
                                >
                                    {{ this.loading ? "Saving...." : "Save" }}
                                </button>
                                @if (this.isEdit && this.canDelete) {
                                    <button
                                        class="aw-button aw-button--danger--outline c-margin-right--quarter"
                                        (click)="confirm()"
                                    >
                                        Delete
                                    </button>
                                }
                                @if (this.isEdit && this.canCopy) {
                                    <button
                                        class="aw-button aw-button--outline c-margin-right--quarter"
                                        (click)="copy()"
                                    >
                                        Copy {{ this.name }}
                                    </button>
                                }
                                <button
                                    class="aw-button aw-button--outline c-margin-right--quarter"
                                    id="cancel"
                                    (click)="cancel(true)"
                                >
                                    Cancel
                                </button>
                                @if (this.isEdit && this.type === "F") {
                                    <button
                                        class="aw-button aw-button--outline c-margin-right--quarter"
                                        (click)="inherit()"
                                    >
                                        Inherit
                                    </button>
                                }
                            </div>
                        }
                        @if (this.isEdit && this.showGroupLink) {
                            <div class="c-displayBlock c-margin-top--double cabinetHierarchy">
                                <s25-security-link
                                    itemTypeId="1"
                                    [itemId]="this.event.event_id"
                                    [nodeType]="'F'"
                                ></s25-security-link>
                            </div>
                        }
                    </div>
                }
                <!-- end if no hasSubinet -->
            </div>
        }

        @if (this.deleteConfirmed) {
            <div>
                <s25-ng-event-type-delete
                    (modelValueChange)="this.onChangeDeleteConfirm($event)"
                ></s25-ng-event-type-delete>
            </div>
        }

        @if (this.isCopy && this.copyFolderList) {
            <div>
                <p class="ngBold">Copy {{ this.name }}: {{ this.event.event_name }}</p>
                <div class="c-margin-top--single copyFolder">
                    <span class="ngBold">Select Cabinet Type for Copied {{ this.name }}:</span>
                    <s25-ng-hierarch-dropdown
                        [items]="this.copyFolderList"
                        [searchEnabled]="true"
                        (chosenChange)="onChangeCopyFoldeList($event)"
                    ></s25-ng-hierarch-dropdown>
                </div>
                @if (this.isSameParentId) {
                    <div class="c-margin-top--half  ngBold cn-alert cn-alert--warning">
                        Note: There is already a {{ this.name.toLowerCase() }} with this name under this parent cabinet
                        type. The copied {{ this.name.toLowerCase() }} will have its name prefixed with "Copy of".
                    </div>
                }
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    [disabled]="!this.copyEnabled || this.loading"
                    (click)="runCopyAction()"
                >
                    Copy {{ this.name }}
                </button>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AdvCabinetFolderComponent implements OnInit {
    @Input() isEdit: any;
    @Input() type: string = "C";
    @Input() typeId: any;
    @Input() itemTypeId: number = 2;
    @Input() isCopy?: boolean = false;
    init = false;
    loading = false;
    allTypes: any;
    cabinets: any;
    parentId: number = 0;
    name: any;
    chosenCabinet: any;
    parentName: string = null;
    msg: any;
    event: EventTypeFormI = undefined;
    valid: boolean = true;
    chosenSubCabinet: any;
    endDate: any = {
        date: S25Util.date.toEndOfDay(
            S25Util.date.createDate(new Date().getFullYear() + 1, new Date().getMonth() + 1, 1),
        ),
        showToday: false,
    };
    startDate: any = {
        date: S25Util.date.toStartOfDay(
            S25Util.date.createDate(new Date().getFullYear(), new Date().getMonth() + 1, 1),
        ),
        showToday: false,
    };
    eventText: any;
    isCatShow: any;
    catSelectedItems: any = [];
    modelBeanCat: any = { showResult: true, showMatching: false };
    primaryOrg: DropDownItem = null;
    othersOrgSelectedItems: any = [];
    modelBeanOthersOrg: any = { showResult: true, showMatching: false };
    chosen: DropDownItem = null;
    items: DropDownItem[];
    subCabinets: any;
    subCabinetsFilter: any;
    eventTypes: any;
    chosenEventType: any;
    hasSubCabinets: any;
    newProfileName: any;
    newProfileType = -1;
    catSelectedItemsInit: any = [];
    othersOrgSelectedItemsInit: any = [];
    eventProfile: any = [];
    copyFolderList: any;
    copyEnabled = false;
    origEventId: any;
    perms = ["EDIT", "CREATE_FLD", "FLD_EDIT", "FLD_CREATE_FLD", "FLD_CREATE_EV", "CREATE_EV", "EV_EDIT"];
    profUse = [
        { itemTypeId: -1, itemName: "Exclude" },
        { itemTypeId: -2, itemName: "Warning" },
    ];
    copyEvent: any = {};
    primaryOrgInit: DropDownItem = null;
    eventTextStatus: any;
    groupsLink: any = S25Util.getURL("", "/groups") + "/#/event/multi/event_multi_pivot/ols?group_id=all&event_id=";
    parentStartDate: any;
    parentEndDate: any;
    validateStartDate: any = true;
    validateEndDate: any = true;
    isValidDate: any = true;
    showCabientErrorMsg: boolean = false;
    isDirty: boolean = false;
    hasSubFolder: boolean = false;
    addSubFolder: boolean = false;
    chosenFolder: any;
    folders: any;
    subFolderStaticItems = [
        {
            dropDownOrigName: "No Sub Folders",
            isSelected: true,
            itemId: 0,
            itemName: "No Sub Folders",
            noAnchorClick: true,
            txt: "No Sub Folders",
            val: "",
        },
    ];
    isSameParentId: boolean = false;
    deleteConfirmed: boolean = false;
    isAddOrgInit: boolean = false;
    canDelete: boolean = false;
    canEdit: boolean = false;
    canCopy: boolean = false;
    groupId: number;
    isEventPerm: boolean = false;
    showGroupLink: boolean = false;
    showNestedFolders: boolean = true;

    getDataDate: any = new Date().getFullYear() - 1 + "1231"; //'20171231';

    selectedDate = 0;

    parentCategory: any;

    isPrimaryOrgInit: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private location: Location,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        // once we completed done migration JS to TS not longer use ui-router. we can use activeRouter/active route in core module to subscribe url variables changed instead of get url variables
        //href:  getHref, getUrlTypeId: getUrlTypeId, getUrlType: getUrlType, getUrlMode: getUrlMode, getUrlItemTypeId: getUrlItemTypeId
        let data = HierarchyUtil.getUrlParam();

        if (data.getUrlMode === "new" && parseInt(data.getUrlTypeId) === 0) {
            this.type = data.getUrlType;
            this.itemTypeId = data.getUrlItemTypeId;
        }

        if (data && data.getUrlDate) {
            this.selectedDate = parseInt(data.getUrlDate);
        }

        this.type === "C" ? (this.name = "Cabinet") : (this.name = "Folder");

        if (this.itemTypeId !== 1) {
            this.zone.run(() => {
                Promise.all([
                    this.getAllEventTypes(),
                    !this.isEdit ? this.postEvent() : this.getEventById(this.typeId),
                    this.getFls(),
                    this.getGroupId(),
                ]).then((resp) => {
                    if (this.groupId === -1 || this.isEventPerm) {
                        this.canCopy = true;
                        this.canDelete = true;
                        this.canEdit = true;
                        this.showGroupLink = true;
                    } else {
                        if (data.getUrlMode === "edit") {
                            this.getOls();
                        } else if (data.getUrlMode === "new") {
                            this.canEdit = true;
                        } else {
                            // do nothing ie View
                        }
                    }
                    this.init = true;
                    // if folder, let's getFolder's parent  start and end dates
                    this.type === "F" && this.isEdit && this.event.parent_id
                        ? this.getParentInfoById(this.event.parent_id)
                        : "";
                    this.isCopy ? this.copy() : "";
                    this.detectChanges();
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                });
            });
        }
    }

    setProfileStatus(id: any, i?: any) {
        let profile = S25Util.array.getByProp(this.eventProfile, "profile_id", id);
        if (profile && profile.status != "new") {
            profile.status = "mod";
        }
    }

    validateProfile = (dt: Date): boolean | string => {
        return S25Util.date.isBetweenEqual(dt, this.event.start_date, this.event.end_date)
            ? true
            : "Constraints must be inside the cabinet/folder dates.";
    };

    //get top cabinet level
    getAllEventTypes() {
        return EventService.getAllEventType().then((data) => {
            data = S25Util.array.forceArray(data);
            this.allTypes = data;
            this.cabinets = data.filter((i: any) => i.parent_id === 0 && i.node_type === "C"); // Toplevel Cabinets
            this.cabinets = this.cabinets.map((obj: any) => {
                return { itemName: obj.type_name, itemId: obj.type_id };
            });
            this.detectChanges();
        });
    }

    postEvent() {
        return EventService.postEvent().then((data) => {
            this.event = data;
            this.event && this.event.event_text ? delete this.event.event_text : "";
            this.event && this.event.custom_attributes ? delete this.event.custom_attributes : "";
            this.event && this.event.category ? delete this.event.category : "";
            this.event && this.event.organization ? delete this.event.organization : "";
            this.event && this.event.role ? delete this.event.role : "";
            this.event && this.event.profile ? delete this.event.profile : "";
            if (this.type === "C") {
                this.event.event_name = "New Cabinet " + this.event.event_id;
                this.event.start_date = S25Datefilter.transform(this.startDate.date, "yyyy-MM-dd");
                this.event.end_date = S25Datefilter.transform(this.endDate.date, "yyyy-MM-dd");
            } else {
                this.event.event_name = "New Folder " + this.event.event_id;
            }
            this.type === "F" ? this.getSubCabinets() : ""; // if folder get level 2 cabinets
            this.getDate(this.event.start_date, this.event.end_date);
            this.detectChanges();
        });
    }

    getEventById(id: any) {
        return EventService.getEventById(id).then((data) => {
            this.event = data;
            this.event.event_text ? (this.eventText = this.event.event_text.text) : "";

            if (this.event.profile) {
                this.event.profile = S25Util.array.forceArray(this.event.profile);
                this.eventProfile = S25Util.deepCopy(this.event.profile);

                for (let p of this.eventProfile) {
                    p.profileModel = {};
                    p.dates = {
                        evStartDt: S25Util.date.parseDropTZ(p.init_start_dt),
                        evEndDt: S25Util.date.parseDropTZ(p.init_end_dt),
                    };
                    if (p.rec_type_id === 2) {
                        p.profile_code = "adhoc";
                        let dtList = S25Util.array.forceArray(S25Util.propertyGet(p, "ad_hoc_datelist"));
                        p.occurrences = dtList.map((d: any) => {
                            return d.ad_hoc_start_dt;
                        });
                    }
                }
            }
            this.getDate(this.event.start_date, this.event.end_date);

            this.getEventTypeById(data.event_type_id); // if folder check show Category or not

            this.detectChanges();
        });
    }

    getParentInfoById(id: any) {
        return EventService.getEventById(id).then((data) => {
            if (data) {
                this.parentStartDate = data.start_date;
                this.parentEndDate = data.end_date;
            }
        });
    }

    getSubCabinets() {
        return EventService.getNodeTypeCabinet().then((data) => {
            data = S25Util.array.forceArray(data);
            this.subCabinets = data;
            this.subCabinets = this.subCabinets.map((obj: any) => {
                return {
                    itemName: obj.event_name,
                    itemId: obj.event_id,
                    typeId: obj.event_type_id,
                    startDate: obj.start_date,
                    endDate: obj.end_date,
                };
            });
            this.detectChanges();
        });
    }

    getEventTypeById(id: any) {
        return EventService.getEventTypeById(id).then((data) => {
            let getCat = S25Util.array.getByProp(data.inheritance_rules, "inherit_item", "Category");
            getCat ? (this.isCatShow = getCat.show_data) : (this.isCatShow = "");
            if (this.isEdit) {
                this.setSelectedItems();
            }
            this.detectChanges();
        });
    }

    setSelectedItems() {
        this.event.category
            ? (this.catSelectedItems = HierarchyUtil.decorateSelectedItems(
                  this.event.category,
                  "category_id",
                  "category_name",
              ))
            : (this.catSelectedItems = []);
        if (this.event.organization) {
            this.event.organization = S25Util.array.forceArray(this.event.organization);
            this.primaryOrg = this.event.organization.filter((i: any) => i.primary === "T");
            this.primaryOrg
                ? (this.primaryOrg = HierarchyUtil.decorateSelectedItems(
                      this.primaryOrg,
                      "organization_id",
                      "organization_name",
                  ))
                : "";
            this.primaryOrg ? (this.primaryOrg = this.primaryOrg[0]) : "";
            this.othersOrgSelectedItems = this.event.organization.filter((i: any) => i.primary != "T");
            this.othersOrgSelectedItems = HierarchyUtil.decorateSelectedItems(
                this.othersOrgSelectedItems,
                "organization_id",
                "organization_name",
            );
        }
        this.primaryOrg ? (this.primaryOrgInit = S25Util.deepCopy(this.primaryOrg)) : (this.primaryOrg = {});
        this.catSelectedItems.length > 0 ? (this.catSelectedItemsInit = S25Util.deepCopy(this.catSelectedItems)) : "";
        this.othersOrgSelectedItems.length > 0
            ? (this.othersOrgSelectedItemsInit = S25Util.deepCopy(this.othersOrgSelectedItems))
            : (this.othersOrgSelectedItems = []);
        this.isAddOrgInit = true;
        this.isPrimaryOrgInit = true;
    }

    initFunc() {
        if (this.isEdit) {
            this.setSelectedItems();
            this.primaryOrg = {};
            this.event.profile ? (this.eventProfile = this.event.event_profile) : (this.eventProfile = []);
        } else {
            this.catSelectedItems = [];
            this.catSelectedItemsInit = [];
            this.othersOrgSelectedItems = [];
            this.othersOrgSelectedItemsInit = [];
            this.event.profile ? (this.eventProfile = this.event.event_profile) : (this.eventProfile = []);
            this.event.event_type_id = "";
            this.chosenCabinet = "";
            this.event.event_text = {};
            this.chosenEventType = "";
            this.chosenSubCabinet = "";
            this.chosenFolder = "";
            this.primaryOrg = {};
        }
        this.eventText = "";
        RichTextEditorApi.destroy(this.elementRef.nativeElement);
    }

    getDate(startDate: any, endDate: any) {
        if (startDate != "" || endDate != "") {
            this.startDate = { date: S25Util.date.parse(startDate), showToday: false };
            this.endDate = { date: S25Util.date.parse(endDate), showToday: false };
        }
    }

    onChangeDes(e: any) {
        this.eventText = e;
        this.event.event_text ? (this.eventTextStatus = "mod") : (this.eventTextStatus = "new");
        this.detectChanges();
    }

    onChangeName(e: any) {
        this.event.event_name = e.target.value;
        !this.event.event_type_id ? (this.showCabientErrorMsg = true) : false;
        this.isDirty = true;
        this.detectChanges();
    }

    onChangeDate(e: any, type: any) {
        if (type === "start") {
            this.event.start_date = S25Datefilter.transform(e, "yyyy-MM-dd");
            if (this.type === "F") {
                this.validateStartDate = S25Util.date.isBetweenEqual(
                    new Date(this.event.start_date),
                    new Date(this.parentStartDate),
                    new Date(this.parentEndDate),
                )
                    ? true
                    : false;
            }
            this.isValidDate =
                new Date(this.event.start_date) < new Date(this.event.end_date)
                    ? true
                    : "The start Date must be before the end date.";
            this.detectChanges();
        } else {
            this.event.end_date = S25Datefilter.transform(e, "yyyy-MM-dd");
            if (this.type === "F") {
                this.validateEndDate = S25Util.date.isBetweenEqual(
                    new Date(this.event.end_date),
                    new Date(this.parentStartDate),
                    new Date(this.parentEndDate),
                )
                    ? true
                    : false;
            }
            this.isValidDate =
                new Date(this.event.start_date) < new Date(this.event.end_date)
                    ? true
                    : "The start Date must be before the end date.";
            this.detectChanges();
        }
    }

    onChangeCabinet(e: any) {
        if (this.type === "F") {
            //if folder need to get subcabinet
            this.showNestedFolders = false;
            this.subCabinetsFilter = this.subCabinets.filter((i: any) => i.typeId === e.itemId);
            let el: any = "s25-ng-hierarch-dropdown";
            // let elFolder: any = "s25-ng-subfolders-dropdown";
            let elFolderType: any = "s25-ng-folder-type-dropdown";
            DropdownApi.refresh(el, this.subCabinetsFilter);
            DropdownApi.refresh(elFolderType, this.eventTypes);
            this.chosenSubCabinet = "";
            this.chosenEventType = "";
            this.chosenFolder = "";
            this.folders = "";
            this.eventTypes = "";
            this.detectChanges();
        } else {
            if (!this.isEdit) {
                this.event.event_type_id = e.itemId;
                this.event.parent_id = 0;
                this.event.node_type = "C";
                this.event.node_type_name = "cabinet";
                //// let delete all nodes that no need in cabinet, otherwise got 403 error
                delete this.event.event_text;
                delete this.event.organization;
                delete this.event.profile;
                delete this.event.role;
                this.getEventTypeById(e.itemId); // cabinet need to get eventType inherit rule show Cat or not
                this.showCabientErrorMsg = false;
                this.detectChanges();
            }
        }
        delete this.event.custom_attribute;
        this.hasSubCabinets = true;
        this.detectChanges();
    }

    onChangeSubCabinet(e: any) {
        this.showNestedFolders = false;
        this.showCabientErrorMsg = false;
        this.chosenFolder = "";
        this.setParentValues(e);
        this.getSubCabinetFolders(e.itemId);
        this.detectChanges();
        this.showNestedFolders = true;
    }

    getSubCabinetFolders(id: any) {
        return EventService.getCabinetFolders(id).then((data) => {
            if (data) {
                this.hasSubFolder = true;
                this.folders = S25Util.array.forceArray(data);
                this.folders = this.folders.map((obj: any) => {
                    return {
                        itemName: obj.event_name,
                        itemId: obj.event_id,
                        typeId: obj.event_type_id,
                        startDate: obj.start_date,
                        endDate: obj.end_date,
                    };
                });
            } else {
                this.hasSubFolder = false;
                this.folders = [];
            }

            let el: any = "s25-ng-subfolders-dropdown";
            DropdownApi.refresh(el, this.folders);
            let elEventType: any = "s25-ng-folder-type-dropdown";
            DropdownApi.refresh(elEventType, this.eventTypes);
            this.detectChanges();
        });
    }

    setParentValues(e: any) {
        this.chosenEventType = "";
        this.event.event_type_id = "";
        this.getDate(e.startDate, e.endDate);
        this.parentStartDate = e.startDate;
        this.parentEndDate = e.endDate;
        this.event.start_date = this.parentStartDate;
        this.event.end_date = this.parentEndDate;
        this.event.parent_id = e.itemId;
        this.event.cabinet_id = e.itemId;
        this.event.cabinet_name = e.itemName;
        this.eventTypes = this.allTypes.filter((i: any) => i.parent_id === e.typeId && i.node_type === "F");
        this.eventTypes = this.eventTypes.map((obj: any) => {
            return { itemName: obj.type_name, itemId: obj.type_id, parentId: obj.parent_id };
        });
        let el: any = "s25-ng-folder-type-dropdown";
        DropdownApi.refresh(el, this.eventTypes);
    }

    onChangeFolder(e: any) {
        if (e && e.itemId > 0) {
            this.setParentValues(e);
        } else {
            this.setParentValues(this.chosenSubCabinet); // if select no folder reset parentValue to upper level (SubCabinet)
            this.chosenFolder = "";
            this.addSubFolder = false;
        }
        this.detectChanges();
    }

    onChangeCopyFoldeList(e: any) {
        this.type === "F" ? (this.copyEvent.parent_id = e.itemId) : (this.copyEvent.event_type_id = e.itemId);
        if (this.type === "F" && this.copyEvent.parent_id === this.event.parent_id) {
            //folder check parent_id
            this.copyEvent.event_name = "Copy of " + this.event.event_name;
            this.isSameParentId = true;
            return EventService.getEventById(e.itemId).then((data) => {
                if (data) {
                    if (data.category) {
                        this.parentCategory = data.category;
                        this.copyEvent.category = this.parentCategory;
                        this.copyEvent.category = S25Util.array.forceArray(this.copyEvent.category);
                        this.copyEvent.category.forEach((item: any) => {
                            item.status = "new";
                        });
                    } else {
                        this.parentCategory = "";
                    }
                }
                this.copyEnabled = true;
                this.detectChanges();
            });
        } else if (this.type === "C" && this.copyEvent.event_type_id === this.event.event_type_id) {
            // cabinet check same event type id or not
            this.copyEvent.event_name = "Copy of " + this.event.event_name;
            this.copyEvent.parent_id = e.itemId;
            this.copyEvent.start_date = this.event.start_date;
            this.copyEvent.end_date = this.event.end_date;
            this.copyEvent.node_type = this.event.node_type;
            this.isSameParentId = true;
        } else {
            // did not select same Folder/cabinet
            this.isSameParentId = false;
            this.copyEvent.event_name = this.event.event_name; //ANG-3804 reset event name, run into issues that user selected to Same parent folder/cabient, then choose a different fodler/cabinet type
            //Fixed ANG-3804
            if (this.type === "C") {
                this.copyEvent.parent_id = e.itemId;
                this.copyEvent.start_date = this.event.start_date;
                this.copyEvent.end_date = this.event.end_date;
                this.copyEvent.node_type = this.event.node_type;
            }
        }
        this.copyEnabled = true;
    }

    onChangeEventType(e: any) {
        this.event.event_type_id = e.itemId;
        this.getEventTypeById(e.itemId);
    }

    chosenOrg(e: any) {
        //console.log({e: e});
    }

    view() {
        this.location.back();
    }

    save() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.validate();
        if (this.valid) {
            this.loading = true;
            this.detectChanges();
            let saveMsg = "ET_I_CREATED";
            this.isEdit ? (saveMsg = "ET_I_SAVED") : "";
            let eventId = this.event.event_id;
            let event = this.event;
            if (!this.isCopy) {
                this.isEdit ? (this.event.status = "mod") : (this.event.status = "new");
                this.event.start_date === ""
                    ? (this.event.start_date = S25Datefilter.transform(this.startDate.date, "yyyy-MM-dd"))
                    : "";
                this.event.end_date === ""
                    ? (this.event.end_date = S25Datefilter.transform(this.endDate.date, "yyyy-MM-dd"))
                    : "";
                this.decorateProfileDataToSave();
                if (this.modelBeanCat.selectedItems) {
                    this.event.category = HierarchyUtil.decorateItemsToSave(
                        this.modelBeanCat.selectedItems,
                        this.catSelectedItemsInit,
                        this.modelBeanCat.removedItems,
                        "category_id",
                        "category_name",
                        this.isEdit,
                        "category_defn_state",
                        1,
                    );
                }
                if (this.type === "F") {
                    let otherOrg = HierarchyUtil.decorateItemsToSave(
                        this.modelBeanOthersOrg.selectedItems,
                        this.othersOrgSelectedItemsInit,
                        this.modelBeanOthersOrg.removedItems,
                        "organization_id",
                        "organization_name",
                        this.isEdit,
                        "primary",
                        "F",
                    );
                    if (this.primaryOrg) {
                        let find: any;
                        if (this.event.organization) {
                            find = this.event.organization.find((a: any) => {
                                return a && a.organization_id === this.primaryOrg.itemId;
                            });
                        }
                        if (this.primaryOrgInit && this.primaryOrgInit.itemId != this.primaryOrg.itemId && !find) {
                            if (this.primaryOrg.itemId !== 0) {
                                otherOrg.push({
                                    organization_id: this.primaryOrg.itemId,
                                    organization_name: this.primaryOrg.itemName,
                                    status: "new",
                                    primary: "T",
                                }); // add new one
                            }
                            otherOrg.push({
                                organization_id: this.primaryOrgInit.itemId,
                                organization_name: this.primaryOrgInit.itemName,
                                status: "del",
                                primary: "T",
                            }); //set old primary org to delete
                        } else if (!this.primaryOrgInit && this.primaryOrg) {
                            otherOrg.push({
                                organization_id: this.primaryOrg.itemId,
                                organization_name: this.primaryOrg.itemName,
                                status: "new",
                                primary: "T",
                            }); // add new one
                        } else {
                            let findInOther = otherOrg.find((a: any) => {
                                return a && a.organization_id === this.primaryOrg.itemId;
                            });
                            if (findInOther) {
                                otherOrg.push({
                                    organization_id: this.primaryOrgInit.itemId,
                                    organization_name: this.primaryOrgInit.itemName,
                                    status: "est",
                                    primary: "T",
                                });
                            }
                        }
                    } //end primaryOrg
                    otherOrg && otherOrg.length > 0 ? (this.event.organization = otherOrg) : "";
                } // end if Folder

                if (this.eventText) {
                    this.event.event_text = {
                        status: this.eventTextStatus,
                        text_type_id: 1,
                        text_type_name: "Description",
                        text: this.eventText,
                    };
                }
            } else {
                eventId = this.copyEvent.event_id;
                event = this.copyEvent;
            }

            LockService.lock(1, eventId);
            EventService.putEvent(eventId, event).then(
                (resp: any) => {
                    LockService.delete(eventId, 1);
                    if (resp.events) {
                        if (this.event.event_id) {
                            //return to hierarchy on save with new folder/cabinet highlighted
                            if (this.isCopy) {
                                OlsService.copyCabinetFolderOls(1, this.origEventId, this.copyEvent.event_id).then(
                                    (data: any) => {
                                        this.isCopy = false;
                                        this.loading = false;
                                        S25LoadingApi.destroy(this.elementRef.nativeElement);
                                        this.goToHierarchy(this.copyEvent.event_id);
                                        window.location.reload();
                                    },
                                );
                            } else {
                                this.loading = false;
                                S25LoadingApi.destroy(this.elementRef.nativeElement);
                                this.goToHierarchy(this.event.event_id);
                            }
                        }
                        this.detectChanges();
                    } else {
                        S25Util.showError(resp, null);
                        return jSith.reject({ response: "error" });
                    }
                },
                function (error) {
                    this.loading = false;
                    let jsonErr = error && error.data && S25Util.prettifyJson(error.data);
                    if (jsonErr.results && jsonErr.results.msg) {
                        alert(jsonErr.results.msg);
                    } else if (
                        jsonErr.results &&
                        jsonErr.results.error_details &&
                        jsonErr.results.error_details.error_detail & jsonErr.results.error_details.error_detail.content
                    ) {
                        alert(jsonErr.results.error_details.error_detail.content);
                    } else if (jsonErr.results && jsonErr.results.error && jsonErr.results.error.msg) {
                        alert(jsonErr.results.error.msg);
                    }
                },
            );
        }
    }

    cancel(e?: any) {
        this.goToHierarchy(this.typeId);
    }

    inherit() {
        return AlertService.confirm(
            "Are you sure you want to Inherit this " + this.name.toLowerCase() + "'s parent?",
        ).then((answer) => {
            if (answer) {
                let id = this.event.event_id.toString();
                return EventService.postEventInherit(id, "folder").then((resp) => {
                    if (resp && resp.results && resp.results.result) {
                        this.getResults(resp.results.result.key);
                    }
                });
            }
        });
    }

    getResults(key: any) {
        return EventService.getResults(key).then((resp) => {
            if (
                (resp && resp.results && resp.results.result && resp.results.result.key) ||
                (resp && resp.result && resp.result.key)
            ) {
                this.getResults(key);
            } else {
                return AlertService.confirm("Inherit to folder complete.").then((confirmed) => {
                    if (confirmed) {
                        this.goToHierarchy(this.event.event_id);
                    }
                });
            }
        });
    }

    delete() {
        return EventService.getCabinetFolders(this.event.event_id).then((data) => {
            let msg;
            if (this.type === "C" && data) {
                msg =
                    "Note that this cabinet contains items (folders/events) that will also get deleted.\n\n" +
                    "Are you sure you want to delete this " +
                    this.name.toLowerCase() +
                    "?";
            } else if (this.type === "F" && data) {
                msg =
                    "Note that this folder contains items (folders/events) that will also get deleted.\n\n" +
                    "Are you sure you want to delete this " +
                    this.name.toLowerCase() +
                    "?";
            } else {
                msg = "Are you sure you want to delete this " + this.name.toLowerCase() + "?";
            }

            return AlertService.confirm(msg).then((answer) => {
                if (answer) {
                    LockService.lock(1, this.event.event_id);
                    return EventService.deleteEvent(this.event.event_id).then((resp) => {
                        if (resp && resp.success) {
                            LockService.delete(this.event.event_id, 1);
                            alert(this.name + " has been deleted.");
                            this.goToHierarchy(1);
                        }
                    });
                }
            });
        });
    }

    confirm() {
        this.deleteConfirmed = true;
    }

    onChangeDeleteConfirm(e: any) {
        if (e === "confirmed") {
            this.delete();
        } else {
            this.deleteConfirmed = false;
        }
    }

    copy() {
        this.isCopy = true;
        this.origEventId = S25Util.deepCopy(this.event.event_id);
        this.copyEvent = {
            event_name: this.event.event_name,
            event_type_id: this.event.event_type_id,
            parent_id: "",
            status: "new",
        };

        if (this.type === "F") {
            if (this.event.organization) {
                this.copyEvent.organization = this.event.organization;
                this.copyEvent.organization = S25Util.array.forceArray(this.copyEvent.organization);
                this.copyEvent.organization.forEach((item: any) => {
                    item.status = "new";
                });
            }
            // get the copy event cabinet list, so new folder has option to select which cabient copy to....
            return EventService.getEventById(this.event.parent_id).then((data) => {
                EventService.getEventListByEventTypeId(data.event_type_id).then((data) => {
                    this.copyFolderList = S25Util.array.forceArray(data);
                    this.copyFolderList = this.copyFolderList.map((obj: any) => {
                        return { itemName: obj.name, itemId: obj.id };
                    });
                    this.isEdit = false;
                    this.detectChanges();
                });
            });
        } else {
            this.copyFolderList = this.cabinets;
        }
    }

    runCopyAction() {
        return EventService.postEvent().then((data) => {
            this.copyEvent.event_id = data.event_id;
            this.detectChanges();
            this.save();
        });
    }

    validate() {
        this.msg = "";
        if (!this.event.event_type_id && !this.isEdit) {
            this.msg = "Please select a cabinet";
            alert(this.msg);
            this.valid = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }

        if (!this.event.event_name) {
            this.msg = "Event  name is required";
            alert(this.msg);
            this.valid = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        return;
    }

    removeProfile(id: any) {
        let profile = S25Util.array.getByProp(this.eventProfile, "profile_id", id);
        if (profile.status === "new") {
            S25Util.array.inplaceRemoveByProp(this.eventProfile, "profile_id", id);
        } else {
            profile.status = "del";
        }
    }

    addConstraint() {
        let getLen = this.eventProfile.length;
        let newProfile = {
            profile_id: "new-" + getLen,
            profile_name: "",
            profile_code: "dnr",
            prof_use: -1,
            rec_type_id: 1,
            rec_type_name: "Recurrence Grammar",
            init_start_dt: S25Util.date.clone(this.startDate.date),
            init_end_dt: S25Util.date.toEndOfDay(S25Util.date.clone(this.startDate.date)),
            status: "new",
            dates: {
                evStartDt: new Date(this.startDate.date || S25Util.date.toStartOfDay(new Date())),
                evEndDt: S25Util.date.toEndOfDay(new Date(this.startDate.date || new Date())),
            },
        };
        this.eventProfile[getLen] = newProfile;
        this.detectChanges();
    }

    updateProfileStatus(id: any, status: any) {
        if (S25Util.array.isArray(this.event.profile)) {
            jSith.forEach(this.event.profile, (key, c) => {
                c.profile_id === id ? (c.status = status) : "";
            });
        } else {
            this.event.profile.profile_id === id ? (this.event.profile.status = status) : "";
        }
    }

    decorateAdHocList(model: any) {
        jSith.forEach(model.profileModel?.occurrences, (key, a) => {
            a = S25Datefilter.transform(a, "yyyy-MM-dd"); // convert date format same format as occurrences.ad_hoc_start_dt
            let find: any;
            if (model.ad_hoc_datelist) {
                model.ad_hoc_datelist = S25Util.array.forceArray(model.ad_hoc_datelist);
                find = model?.ad_hoc_datelist?.find((b: any) => {
                    return b && b.ad_hoc_start_dt === a;
                });
            }

            if (!find) {
                model.ad_hoc_datelist = S25Util.array.forceArray(model.ad_hoc_datelist);
                // add one occurrence
                model.ad_hoc_datelist.push({
                    ad_hoc_start_dt: a,
                    status: "new",
                });
            }
        });
        //check original ad hoc occurrences if no in the new occurrences, set status to delete
        let occCopy = S25Util.deepCopy(model?.profileModel?.occurrences);
        jSith.forEach(model.ad_hoc_datelist, (key, a) => {
            let find = occCopy.findIndex((b: any) => {
                return b && S25Datefilter.transform(b, "yyyy-MM-dd") === a.ad_hoc_start_dt;
            });

            delete a.evStartDt; // no need this to save
            if (find === -1) {
                a.status = "del";
            } else {
                occCopy.splice(find, 1); //ANG-4784 Cleanup duplicate dates if they exist, they shouldn't
            }
        }); // set status to delete
    }

    decorateProfileDataToSave() {
        let profileTemp = S25Util.deepCopy(this.eventProfile);
        let decoratedProfiles: any = [];

        jSith.forEach(profileTemp, (key, c) => {
            if (c.profile_code && c.profile_code === "adhoc") this.decorateAdHocList(c);
            let recTypeId;
            switch ((c.profileModel && c.profileModel.type) || c.profile_code) {
                case "adhoc":
                    recTypeId = 2;
                    break;
                case "dnr":
                    recTypeId = 0;
                    break;
                case "weekly":
                case "monthly":
                case "daily":
                    recTypeId = 1;
                    break;
                default:
                    recTypeId = c.rec_type_id;
            }

            let ret = {
                status: c.status,
                profile_id: c.status === "new" ? "" : c.profile_id,
                profile_code: ["dnr", "adhoc"].indexOf(c.profile_code) > -1 ? "" : c.profile_code,
                profile_name: c.profile_name,
                init_start_dt: S25Util.date.dropTZString(c.dates.evStartDt),
                init_end_dt: S25Util.date.dropTZString(c.dates.evEndDt),
                prof_use: c.prof_use,
                prof_use_name: c.prof_use_name, //warning | exclude
                rec_type_id: recTypeId, //Repeat type. 0 - dnr, 1 - pattern, 2 - ad hoc
                ad_hoc_datelist: c.ad_hoc_datelist, //already decorated in decorateAdHocList above
            };
            decoratedProfiles.push(ret);
        });
        this.event.profile = decoratedProfiles;
    }

    goToHierarchy(id?: any) {
        id === "undefined" || !id ? (id = 1) : "";

        let hierarchyUrl =
            HierarchyUtil.getCompUrl("hierarchy", this.selectedDate) +
            "/" +
            this.itemTypeId +
            "/view/" +
            this.type +
            "/" +
            id;
        window.location.assign(hierarchyUrl); // assign url and reload
    }

    detectChanges() {
        try {
            this.cd.detectChanges();
        } catch (error: any) {}
    }

    removePrimaryOrg() {
        this.primaryOrg = {};
        this.detectChanges();
    }

    getFls() {
        return FlsService.getFls().then((data: any) => {
            if (this.itemTypeId === 2 && data.CABINET_DELETE === "F") {
                this.canDelete = true;
            } else if (this.itemTypeId === 3 && data.FOLDER_DELETE === "F") {
                this.canDelete = true;
            }
            data.EVENT_PERM === "F" ? (this.isEventPerm = true) : "";
        });
    }

    getOls() {
        return OlsService.getOls(this.event.event_id, 1, "edit").then((data: any) => {
            if (data && typeof data === "object") {
                this.canEdit = ["F", "C"].indexOf(data[0].access_level) > -1;
                this.canCopy = ["F"].indexOf(data[0].access_level) > -1;
                this.showGroupLink = ["F"].indexOf(data[0].access_level) > -1;
                ["R", "C", "N"].indexOf(data[0].access_level) > -1 ? (this.canDelete = false) : "";
            }
            this.detectChanges();
        });
    }

    getGroupId() {
        return UserprefService.getGroupId().then((resp: any) => {
            resp ? (this.groupId = resp) : "";
            this.detectChanges();
        });
    }
}
